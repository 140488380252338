@keyframes fadeInOut {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
}

.loading {
    animation: fadeInOut 2s ease-in-out infinite;
}

.btn-active {
    color: #000000;
    background-color: #f7d490;
    box-shadow: 0 0 20px #0dcaf0 ;
}
.btn-active:focus {
    color: #000000;
    background-color: #f7d490;
}

.recharts-text {
    fill: #ffffff !important;
}