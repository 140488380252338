
/* Overrides for Bootstrap styles */
a { color: #c49c50; text-decoration: none; }
a:hover { color: #c49c50; opacity: 0.8; }

body { padding: 0; margin: 0; }

.ccc-button { color: white; }
.ccc-button:hover { color: white; }
.ccc-submenu-item { color: #00122e; }

.nav-link {
    color: white !important;
}

.btn-secondary {
    border: none;
    background-color: #008f51;
    padding: 5px 24px;
}
.btn-secondary:hover {
    background-color: #c49c50;
}

.card {
    border: none;
}

.ccc-emphasis-box {
    padding: 18px;
    background-color: #fbf2d8;
    border: 1px solid #c49c50;
    border-radius: 7px;
}

.title-text {
    font-size: 1.5em;
    font-weight: bold;
    max-width: 40%;
    padding-right: 30px;
    color: #ffffff;
    position: absolute;
    right: 0;
    top: 190px;
}

@media(max-width: 1096px) {
    .title-text {
        top: 150px;
        font-size: 1.2em;
    }
}

@media(max-width: 911px) {
    .title-text {
        font-size: 1em;
        top: 150px;
    }
}

.title-text-mobile {
    font-size: 1.2em;
    font-weight: bold;
    padding: 30px;
}